import { useSelector, useDispatch } from 'react-redux'
import { sessionTokenReducer, userReducer } from '../app/store';

import { useHistory } from 'react-router';

import axios from 'axios';
import serviceName from '../services/serviceName';

export default function useAccount() {
    const dispatch = useDispatch();
    const user = useSelector(state => state.user.value)
    const sessionToken = useSelector(state => state.sessionToken.value)

    const history = useHistory();

    const isLogged = user&&sessionToken;
    const sessionTokenStored = JSON.parse(localStorage.getItem(serviceName('sessionToken')))

    async function loginFromLocal() {
        if(!isLogged && sessionTokenStored) {
            // dispatch(sessionTokenReducer.set(sessionTokenStored));
            return await login(sessionTokenStored)
        }
        return false
    }

    async function login(sessionToken) {
        try {
            const r = await axios({
                method: 'GET',
                url: `${process.env.REACT_APP_API_ENDPOINT}/api/user`,
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${sessionToken}`
                }
            })


            dispatch(sessionTokenReducer.set(sessionToken))
            dispatch(userReducer.set(r.data.response.user))
            console.log('Logged');
            return true;
        } catch(error) {
            logout();
            return false
        }
    }

    function logout() {
        dispatch(sessionTokenReducer.invalid());
        dispatch(userReducer.invalid());
        // history.push('/')
    }

    return {
        loginFromLocal,
        login,
        logout,
        isLogged,
        isGuest: !isLogged,
        sessionToken,
        user
    }
}