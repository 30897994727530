import React, {useState, useEffect, useRef} from 'react'
import { useSelector } from 'react-redux'
import axios from 'axios'
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from "react-loader-spinner";

export default function DetailsImage({questionId, detail, type='jpg', parentSize, parentImage}) {
    const isLoading = useRef(null);
    const [imageData, setImageData] = useState(false);
    const [showImage, setShowImage] = useState(false);

    const sessionToken = useSelector(state => state.sessionToken.value)
    
    const image = detail.img;
    /*
    width :  100% = parentSize.width
    left  :  ?%   = left px


    */
   
   const renderRatio = parentSize.width / parentImage.width;
   const d = (n,axis)=>`${100*n*renderRatio/parentSize[axis]}%`;


    const MIMES = {
        'jpg': 'image/jpeg',
        'jpeg': 'image/jpeg',
        'gif': 'image/gif',
        'bmp': 'image/bmp',
        'ico': 'image/vnd.microsoft.icon',
        'png': 'image/png',
        'tif': 'image/tiff',
        'tiff': 'image/tiff',
        'webp': 'image/webp',
        'svg': 'image/svg+xml'
    }

    function handleData(data) {
        isLoading.current=false
        let img = Buffer.from(data, 'binary').toString('base64')
        let e = new Image();
        e.onload = function () {
            // console.log(e.width);
            setImageData({
                height: d(e.height,'height'),
                width: d(e.width,'width'),
                src: e.src
            })
        }
        e.src=`data:${MIMES[type]};base64, ${img}`;
    }

    useEffect(()=>{
        if(isLoading.current===null) {
            setTimeout(function() {
                setShowImage(true)
            }, detail.position)

            axios({
                method: 'GET',
                url: `${process.env.REACT_APP_API_ENDPOINT}/api/question/${questionId}/sequence/${image.filename}.${type}`,
                responseType: 'arraybuffer',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${sessionToken}`
                }
            }).then(r=>handleData(r.data));
        }
    })


    return (
        <div 
            className={`detailImage ${(showImage&& isLoading.current===false)?'show':''} absolute justify-center items-center overflow-hidden h-auto w-auto`} 
            style={{
                top: d(image.y, 'height'), 
                left: d(image.x, 'width'),
                height:imageData.height,
                width:imageData.width
            }}
        >
            { (showImage && isLoading.current!==false) &&
                <Loader
                    type="Puff"
                    color="#00BFFF"
                    height={10}
                    width={10}
                /> 
            }
            { (showImage && isLoading.current===false) &&
                <img src={imageData.src} style={{display:'block'}} alt=" " />
            }
        </div>
    )
}