import View from '../components/View';

import { Line } from 'react-chartjs-2';

import axios from 'axios';
import { AnimatePresence } from "framer-motion";

import useAccount from '../hooks/useAccount';
import { useEffect, useRef, useState } from 'react';

export default function Series(props) {
    const account = useAccount();
    const [stats, setStats] = useState([]);

    useEffect(()=>{
        axios({
            method: 'POST',
            url: `${process.env.REACT_APP_API_ENDPOINT}/api/stats`,
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${account.sessionToken}`
            }
        })
        .then(r=>{
            var s = r.data.response.stats || []

            if(!Array.isArray(s)) {
                s = Object.values(s);
            }
            s = s.sort((a,b)=>(a.at - b.at));        
            setStats(s)
        })

    }, []);


    const months = [
        'Janvier',
        'Février',
        'Mars',
        'Avril',
        'Mai',
        'Juin',
        'Juillet',
        'Août',
        'Septembre',
        'Octobre',
        'Novembre',
        'Décembre'
    ];


    const tToDate = date=>{
        let d=new Date(parseInt(date));
        // return `${d.getDate()} ${months[d.getMonth()]} ${d.getFullYear()}`
        return `${d.getDate()}/${(d.getMonth()+1).toString().padStart(2,'0')}/${d.getFullYear()}`
    }
    
    const lastTenSeries = stats.slice(Math.max(stats.length - 10, 0))


    const data1 = {
        labels: lastTenSeries.map(serie=>tToDate(serie.at)),
        // labels: lastTenSeries.map(serie=>`n°${parseInt(serie.serie, 16)}`),
        // labels: [...Array(stats.length).keys()],
        datasets: [
            {
                label: 'Objectif',
                data: Array(10).fill(40),
                fill: false,
                borderColor: 'rgb(0, 179, 0)',
                pointRadius: 0,
                borderDash: [5,5],
                pointHoverRadius:0,
                borderWidth: 0.5
            },
            {
                label: 'Code validé',
                data: Array(10).fill(35),
                fill: false,
                borderColor: 'rgb(0, 179, 0)',
                borderDash: [5,5],
                pointRadius: 0,
                pointHoverRadius:0,
                borderWidth: 1
            },
            {
                label: 'Score',
                data: lastTenSeries.map(serie=>parseInt(serie.results.score)),
                fill: true,
                borderColor: 'rgb(75, 192, 192)',
                pointBackgroundColor: 'rgb(75, 192, 192)',
                pointHoverRadius: 6,
                tension: 0.4
            },
        ]
    };
    

    const options1 = {
        interaction: {
            intersect: false,
            mode: 'index',
        },
        plugins: {
            legend: false,
            // title: {
                // display: true,
                // text: 'Résultats des 10 dernières séries'
            // },
        },
        scales: {
            x: {
                title: {
                    display: true,
                    text: 'Date'
                }
            },
            y: {
                title: {
                    display: true,
                    text: 'Score'
                }
            }
        }
    }

    const data2 = {
        // labels: lastTenSeries.map(serie=>tToDate(serie.at)),
        // labels: lastTenSeries.map(serie=>`n°${parseInt(serie.serie, 16)}`),
        labels: [...Array(stats.length).keys()],
        datasets: [
            {
                label: 'Objectif',
                data: Array(10).fill(40),
                fill: false,
                borderColor: 'rgb(0, 179, 0)',
                pointRadius: 0,
                borderDash: [5,5],
                pointHoverRadius:0,
                borderWidth: 0.5
            },
            {
                label: 'Code validé',
                data: Array(10).fill(35),
                fill: false,
                borderColor: 'rgb(0, 179, 0)',
                borderDash: [5,5],
                pointRadius: 0,
                pointHoverRadius:0,
                borderWidth: 1
            },
            {
                label: 'Score',
                data: stats.map(serie=>parseInt(serie.results.score)),
                fill: true,
                borderColor: 'rgb(75, 192, 192)',
                pointBackgroundColor: 'rgb(75, 192, 192)',
                pointHoverRadius: 6,
                tension: 0.4
            },
        ]
    };

    const options2 = {
        interaction: {
            intersect: false,
            mode: 'index',
        },
        plugins: {
            legend: false,
        },
        scales: {
            x: {
                title: {
                    display: true,
                    text: 'Série'
                }
            },
            y: {
                title: {
                    display: true,
                    text: 'Score'
                }
            }
        }
    }


    return (
        <View>
            <div className="px-4 md:px-8">
                <AnimatePresence exitBeforeEnter>
                    <div className="flex flex-col space-y-32">
                        <div>
                            <h3 className="pl-5 pb-4 text-2xl">10 dernières séries</h3>
                            <Line data={data1} options={options1}/>
                        </div>
                        <div>
                            <h3 className="pl-5 pb-4 text-2xl">Avancée globale</h3>
                            <Line data={data2} options={options2}/>
                        </div>
                    </div>
                </AnimatePresence>
            </div>
        </View>
    );
}