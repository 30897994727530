import React from 'react';
import { motion } from "framer-motion"
import axios from 'axios';
import { useSelector, useDispatch } from 'react-redux'

import { useState, useEffect } from 'react';

import Question from './Question';
import Results from './Results';

export default function SerieQuizz({ serie, onResults, isExamMode }) {
    const questionIds = serie.questions

    const [results, setResults] = useState({
        score:0,
        nbQuestions: questionIds.length,
        details: {}
    });
    const questionOngoingIndex = Object.keys(results.details).length;

    const advancement = {
        actual: questionOngoingIndex+1,
        total: questionIds.length
    };

    const serieEnded = advancement.actual===advancement.total+1;
    // const serieEnded = advancement.actual===2;



    function handleQuestionResult(questionResult) {
        console.log(`${questionOngoingIndex+1}/${questionIds.length} done`);
        const details = {...results.details, [questionOngoingIndex]: questionResult};
        setResults({
            score: ( Object.values(details).filter(qR=>qR.correct===true) ).length,
            nbQuestions: Object.values(details).length,
            details
        })
    }
    useEffect(()=>{
        if(serieEnded) onResults(results)
    }, [results]);

    return (
        <>
            <motion.div 
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            >
                { serieEnded ?
                    <Results results={results}/>
                    :
                    <Question advancement={advancement} isExamMode={isExamMode} id={questionIds[questionOngoingIndex]} onResult={handleQuestionResult}/>
                }
            </motion.div>
        </>

    );
}