import { Link } from 'react-router-dom';
import './styles.css';
import { motion } from "framer-motion"
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from "react-loader-spinner";
import { useRef } from 'react';

export default function SectionLink(props) {
    const isLoading = useRef(false);

    const handleClick = ()=>{
        if(props.onClick) {
            isLoading.current = true;
            props.onClick()
        }
    }

    const cls = `select-none block w-full h-full SectionLink relative overflow-hidden rounded-2xl font-normal bg-gray-700 duration-150 ${props.onClick?'cursor-pointer':''} ${props.disabled?'opacity-20':'enabled'}`;

    const inner = (
        <>
        <div>
            <img src={props.img} alt=" "></img>
            {
                isLoading.current===true&&
                <div className="absolute h-full w-full top-0 flex flex-row">
                    <Loader
                        className="absolute top-2 right-6"
                        type="ThreeDots"
                        color="#222222"
                        height={50}
                        width={50}
                    />
                </div>
            }
        </div>
        <div className="SectionLink-info bg-gray-900 w-full bg-opacity-0 absolute px-6 md:px-10 py-8 md:py-10">
            {/* <div className="SectionLink-bg bg-gray-900 w-full h-full bg-opacity-50 absolute z-10"></div> */}
            <span className="SectionLink-title relative block text-2xl md:text-2xl lg:text-3xl z-10">{props.title}</span>
            <span className="SectionLink-subtitle relative md:text-md lg:text-lg font-thin md:opacity-0 z-10">{props.subtitle}</span>
        </div>
    </>);


    return (
        <div className="relative w-full sm:w-1/2 md:max-w-xl p-2">
            <motion.div 
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
            >
                {(props.disabled || !props.to)?
                    <div className={cls} onClick={handleClick}>
                        {inner}
                    </div>
                :
                    <Link to={props.to} className={cls}>
                        {inner}
                    </Link>
                }
            </motion.div>

        </div>

    );
}