import View from '../components/View';

import FlexGrid from '../components/FlexGrid';
import SectionLink from '../components/SectionLink';
import axios from 'axios';
import { AnimatePresence } from "framer-motion";

import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from "react-router-dom";
import { useEffect, useState } from 'react';
import { serieOngoingReducer } from '../app/store';

export default function Series(props) {
    const sessionToken = useSelector(state => state.sessionToken.value)
    const user = useSelector(state => state.user.value)

    const history = useHistory();
    const dispatch = useDispatch();

    function startNewSerie(examMode) {
        axios({
            method: 'POST',
            url: `${process.env.REACT_APP_API_ENDPOINT}/api/serie/start`,
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${sessionToken}`
            }
        })
        .then(r=>{
            dispatch(serieOngoingReducer.set(r.data.response.serie))
            history.push(`/series/${examMode?'exam':'train'}`);
        })
    }

    useEffect(()=>{
        dispatch(serieOngoingReducer.set(null))
    }, [])

    return (
        <View>
            <div className="px-4 md:px-8">
                <AnimatePresence exitBeforeEnter>
                    <FlexGrid row>
                        <SectionLink 
                            disabled={!user}
                            onClick={()=>startNewSerie(false)}
                            img="/assets/img/pedagogic-1x.jpg" 
                            title="Mode pédagogique" 
                            subtitle="Questions avec explications"
                        />
                        <SectionLink 
                            disabled={!user}
                            onClick={()=>startNewSerie(true)}
                            img="/assets/img/exam-1x.jpg" 
                            title="Mode examen"
                            subtitle="Juste les questions"
                        />
                    </FlexGrid>
                </AnimatePresence>
            </div>
        </View>
    );
}