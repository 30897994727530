export default function FlexGrid(props) {
    const cls = `
        flex-${props.row ? 'row' : 'col'}
        flex-${props.nowrap?'nowrap':'wrap'}
        items-start
    `;
    return (
        <div className={`py-6 md:py-10 flex ${cls}`}>
            {props.children}
        </div>
    );
}