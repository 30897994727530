import { useState} from 'react';
import { motion } from "framer-motion"

export default function Section(props) {
    const [isOpen, setIsOpen] = useState(props.open || false)
    const [isHoverred, setIsHoverred] = useState(false)

    function handleClick() {
        if(props.collapse)
            setIsOpen(!isOpen)
    }

    function handleMouseEnter() {
        if(props.collapse)
            setIsHoverred(true)
    }

    function handleMouseLeave() {
        if(props.collapse)
            setIsHoverred(false)
    }

    return (
        <div className="relative w-full sm:w-1/2 md:max-w-xl p-2">
            <motion.div 
                className={`w-full h-full overflow-hidden rounded-2xl font-normal px-6 md:px-10 py-6 md:py-10 flex flex-col bg-gray-700 ${isHoverred?'bg-opacity-70':'bg-opacity-100'}`}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
            >
                <div 
                    onClick={()=>handleClick()} 
                    onMouseEnter={()=>handleMouseEnter()} 
                    onMouseLeave={()=>handleMouseLeave()} 
                    className={`select-none flex flex-col ${props.collapse && 'cursor-pointer'}`}>
                    <span className="text-2xl md:text-2xl lg:text-3xl">{props.title}</span>
                    <span className="md:text-md lg:text-lg font-thin">{props.subtitle}</span>
                </div>
                {(props.children && (isOpen || !props.collapse)) && <div className="pt-4">
                    {props.children}
                </div>}
            </motion.div>
        </div>

    );
}