

export default function QuestionCard({n, question, onClick, selected}) {


    return (
        <div 
            onClick={()=>onClick(n, question)}
            className={`rounded-lg m-1 px-3 py-1 text-xl bg-${question.correct?'green':'red'}-400 cursor-pointer border-4 ${selected?'border-gray-400':'border-transparent'}`}
        >
            {n}
        </div>
    );

}