import { useState, useEffect } from "react";
import { motion } from "framer-motion"

export default function ChronoBar({duration, onEnd}) {
    return (
        <div className="w-full">
            <motion.div 
                className="bg-white h-1" 
                initial={{ width: 0 }}
                animate={{ width: '100%' }}
                transition={{duration, ease:'linear'}}
                onAnimationComplete={onEnd}
            />
        </div>
    );
}