import { useSelector } from 'react-redux'
import useAudio from '../../hooks/useAudio';
import useCorrectionCard from '../../hooks/cards/useCorrectionCard';

import Image from '../Image';
import React, { useCallback, useEffect, useState } from 'react';

export default function Correction(props) {
    const {enonce, id, proposition, reponse, sequence} = props.data
    const { questionResult } = props;

    const { etape, image, son, nbimages, nbetapes } = sequence[1];

    const [CorrectionCard] = useCorrectionCard({reponse, proposition, questionResult});

    const correctionAudio = useAudio(id, son);

    const details = etape.map(e=>{return {...e, img: image[e.image-1]}})

    const handleContinue = ()=>{
        props.onContinue()
    }

    useEffect(()=>{
        
        return function() {
            correctionAudio.audio.current.pause()
        }
    },[])

    

    const enonceBase = enonce[0];
    const enonceOptional = [enonce[1], enonce[2]];

    return (
        <div className={`bg-gray-700 overflow-hidden rounded-xl border-4 border-${questionResult.correct?'green':'red'}-400`}>
            <div className="relative w-full">
                <Image questionId={id} image={image[0].filename} details={details}/>
            </div>
            <div className="flex flex-col md:px-2">
                {questionResult.answer==='' && <span className={`block p-4 lg: pb-0 font-bold text-${questionResult.correct?'green':'red'}-400`}>Vous avez indiqué qu'aucune réponse n'était correcte </span>}
                <div className="p-4 pb-0 enonce text-lg">
                    {enonceBase}
                </div>
                <div className="flex flex-col">
                    {enonceOptional.map((opt,i)=>{
                        const p = enonce[2]==='ou'?[i]:[2*i,2*i+1]
                        return (
                            <div 
                                className="flex flex-col px-2 pb-4"
                                key={i}
                            >
                                {(opt && opt!=='') && <div className="px-2 text-lg">{opt}</div>}
                                <div className="flex flex-row flex-wrap">
                                    { p.map(i=>(proposition[i] && proposition[i]!=='') && <CorrectionCard i={i} key={i} />) }
                                </div>
                            </div>
                        );
                    })}
                </div>
                <div className="px-6 pb-6 flex flex-row flex-wrap justify-center md:justify-between continue">
                    <div className={`rounded-md py-2 mt-2 px-3 `}>
                        Question n°{props.advancement.actual} sur {props.advancement.total}
                    </div>
                    <div 
                        onClick={handleContinue}
                        className={`rounded-md py-2 px-4 mt-2 bg-green-400 hover:bg-green-500 cursor-pointer`}
                    >Question suivante</div>
                </div>
            </div>
        </div>
    );
}