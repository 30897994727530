import React, {useState, useEffect, useRef} from 'react'
import { useSelector } from 'react-redux'
import axios from 'axios'
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from "react-loader-spinner";

import DetailsImage from '../components/DetailsImage';
import sizeMe from 'react-sizeme';

function ImageEl({questionId, image, type='jpg', details=[], size}) {
    const isLoading = useRef(null);
    const [imageData, setImageData] = useState(false);
    const sessionToken = useSelector(state => state.sessionToken.value)

    const MIMES = {
        'jpg': 'image/jpeg',
        'jpeg': 'image/jpeg',
        'gif': 'image/gif',
        'bmp': 'image/bmp',
        'ico': 'image/vnd.microsoft.icon',
        'png': 'image/png',
        'tif': 'image/tiff',
        'tiff': 'image/tiff',
        'webp': 'image/webp',
        'svg': 'image/svg+xml'
    }

    function handleData(data) {
        isLoading.current=false

        let img = Buffer.from(data, 'binary').toString('base64')
        let e = new Image();
        e.onload = function () {
            setImageData({
                height: e.height,
                width: e.width,
                src: e.src
            })
        }
        e.src=`data:${MIMES[type]};base64, ${img}`;
    }


    useEffect(()=>{
        // if(isLoading.current===null) {
            axios({
                method: 'GET',
                url: `${process.env.REACT_APP_API_ENDPOINT}/api/question/${questionId}/sequence/${image}.${type}`,
                responseType: 'arraybuffer',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${sessionToken}`
                }
            }).then(r=>handleData(r.data));
        // }
    }, [questionId])

    return (
        <div className="relative w-full flex justify-center items-center overflow-hidden">
            { isLoading.current!==false ?
                <Loader
                    type="Puff"
                    color="#00BFFF"
                    height={100}
                    width={100}
                /> :
                <>
                    <img src={imageData.src} alt="(Pas de photo)" />
                    {details.map((detail,i)=><DetailsImage parentSize={size} parentImage={imageData} key={i} questionId={questionId} detail={detail} /> )}
                </>
            }
        </div>
    );
}

export default sizeMe({ monitorHeight: true })(ImageEl);