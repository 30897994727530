import { Redirect } from "react-router-dom";
import useAccount from "../useAccount";

export default function useLoggedGuard() {
    const account = useAccount();

    return {
        name: 'LOGGED',
        condition: ()=>account.isLogged!==null,
        check: function(failedArray){
            if(this.condition()) return true;
            
            failedArray.push(this);
            return false
        },
        fail: async route=>{
            const logged = await account.loginFromLocal()
            return logged?route.component:<Redirect to="/" />
        }
    }
}