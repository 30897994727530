import useCorrectionCard from '../../../hooks/cards/useCorrectionCard';

import Image from '../../Image';
import React, { useCallback, useEffect, useState } from 'react';

import useData from '../../../hooks/useData';

function LittleCorrectionCard({questionData, questionResult, advancement}) {
    const {enonce, id, proposition, reponse, sequence} = questionData

    const { etape, image, son, nbimages, nbetapes } = sequence[1];

    const [CorrectionCard] = useCorrectionCard({reponse, proposition, questionResult});

    const enonceBase = enonce[0];
    const enonceOptional = [enonce[1], enonce[2]];


    return (
        <div className={`bg-gray-700 overflow-hidden rounded-xl border-4 border-${questionResult.correct?'green':'red'}-400 text-left`}>
            <div className="relative w-full">
                <Image questionId={id} image={image[0].filename}/>
            </div>
            <div className="flex flex-col md:px-2">
                {questionResult.answer==='' && <span className={`block p-4 lg: pb-0 font-bold text-${questionResult.correct?'green':'red'}-400`}>Vous avez indiqué qu'aucune réponse n'était correcte </span>}
                <div className="p-4 pb-0 enonce text-lg">
                    {enonceBase}
                </div>
                <div className="flex flex-col">
                    {enonceOptional.map((opt,i)=>{
                        const p = enonce[2]==='ou'?[i]:[2*i,2*i+1]
                        return (
                            <div 
                                className="flex flex-col px-2 pb-4"
                                key={i}
                            >
                                {(opt && opt!=='') && <div className="px-2 text-lg">{opt}</div>}
                                <div className="flex flex-row flex-wrap">
                                    { p.map(i=>(proposition[i] && proposition[i]!=='') && <CorrectionCard i={i} key={i} />) }
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>

            <div className="px-6 pb-6 flex flex-row flex-wrap justify-center md:justify-between continue">
                <div className={`rounded-md py-2 mt-2 px-3 `}>
                    Question n°{advancement.actual} sur {advancement.total}
                </div>
            </div>
        </div>
    );
}




export default function LittleCorrection({questionToShow, advancement}) {

    const [fetchData, questionDataLoading, questionData] = useData();

    useEffect(()=>{
        fetchData(questionToShow.question)
    }, [questionToShow])

    return (
        <div>
            {(questionDataLoading===true) &&
                <Loader
                    type="Puff"
                    color="#00BFFF"
                    height={100}
                    width={100}
                    timeout={3000} //3 secs
                />
            }
            {questionDataLoading===false && questionData && 
                <LittleCorrectionCard questionData={questionData} questionResult={questionToShow} advancement={advancement} />
            }
        </div>



        
    );
}