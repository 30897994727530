import React, { useState } from 'react';
import axios from 'axios';
import { useSelector } from 'react-redux'

import Input from './Input';
import Button from './Button';

export default function Form(props) {


    const sessionToken = useSelector(state => state.sessionToken.value)

    let baseBody = {};

    props.body.forEach(part=>{
        if(part[0] === 'input' || part[0]==='hidden') 
        baseBody[part[1].name] = part[1].value || '';
    })

    const [body, setBody] = useState(baseBody);
    const [isLoading, setIsLoading] = useState(false);
    const [errors, setErrors] = useState({});
    
    function submit() {
        let headers = {
            'Content-Type': 'application/json'
        }
        if(sessionToken) headers['Authorization'] = `Bearer ${sessionToken}`

        setIsLoading(true);
        axios({
            method: props.method,
            url: props.url,
            data: body,
            headers
        })
        .then(r=>{
            if(r.data.success) {
                props.onSuccess(r)
            }
        })
        .catch(e=>{
            if(e.response && e.response.data.errors ) {
                setErrors(e.response.data.errors)
            }
        })
        .finally(()=>{
            setIsLoading(false);
        })
    }

    function handleChange(newValue) {
        setBody({...body, ...newValue.toState});
    }

    return (
        <div>
            {props.body && props.body.map((part,i)=>{
                if(part[0] === 'input')
                    return <Input onChange={newValue=>handleChange(newValue)} {...part[1]} error={errors[part[1].name]} key={part[1].name}/>;
                if(part[0] === 'hidden')
                    return <input type="hidden" {...part[1]} key={part[1].name}/>
                if(part[0] === 'submit')
                    return <Button onClick={()=>submit()}  {...part[1]} key='submit' loading={isLoading}/>;
                else 
                    return <></>;
            })}
        </div>
    );
}