export default function useCorrectionCard({ proposition, questionResult, reponse}) {
    const letters = ['A','B','C','D'];
    return [function(props){
        const letter = letters[props.i]
        const isCorrect = questionResult.answer.includes(letter) && reponse.includes(letter);
        let color = null;

        // reponse est la correction 
        if(questionResult.answer.includes(letter)) {
            // color = reponse.includes(letter) ? ['green','green-400'] : ['red', 'red-400']
            color = reponse.includes(letter) ? ['green','green-400'] : ['red', 'red-400']
        } else {
            // color = reponse.includes(letter) ? ['green','green-400'] : ['gray', 'white']
            color = ['gray', 'white']
        }

        const bdColor = reponse.includes(letter)? 'green-400':'transparent';

        // error = questionResult.answer.includes(letter) && !reponse.includes(letter)
        // correct = questionResult.answer.includes(letter) && reponse.includes(letter)
        // neutre = !questionResult.answer.includes(letter) && !reponse.includes(letter)
        return (
            <div 
                className={`flex flex-row justify-center items-center m-1 p-1 pr-2 rounded-md border-2 border-${bdColor}`}
            >
                <div className={`px-3 py-1 mr-2 rounded-md bg-${color[0]}-400`}>{letter}</div>
                <span className={`text-${color[1]}`}>{proposition[props.i]}</span>
            </div>
        );
    }];
}


