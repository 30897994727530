import Home from '../views/Home';
import Series from '../views/Series';
import Train from '../views/Train';
import Stats from '../views/Stats';
import Error404 from '../views/Error404';
import { useLocation } from 'react-router-dom';

export default function useRoutes() {

    let location = useLocation()
    const routes = [
        {
            name: 'home',
            title: 'Accueil',
            path: '/',
            component: <Home/>
        },
        {
            name: 'series',
            title: 'S\'entraîner',
            path: '/series',
            component: <Series/>,
            guards: 'LOGGED'
        },
        {
            name: 'train',
            title: 'En entraînement',
            path: '/series/train',
            component: <Train examMode={false}/>,
            guards: 'LOGGED && SERIE_ONGOING'
        },
        {
            name: 'exam',
            title: 'En examen',
            path: '/series/exam',
            component: <Train examMode={true}/>,
            guards: 'LOGGED && SERIE_ONGOING'
        },
        {
            name: 'stats',
            title: 'Statistiques',
            path: '/stats',
            component: <Stats/>,
            guards: 'LOGGED'
        },
        {
            name: '404',
            title: 'Page introuvable',
            path: '/*',
            component: <Error404 />
        }
    ];

    const route404 = {...routes.find(route=>route.name='404')};

    const getRouteBy = (prop, searchValue)=>({...(routes.find(route=>route[prop]===searchValue)||route404)});

    const actualRoute = ()=>getRouteBy('path',location.pathname);

    return {
        routes: [...routes],
        getRouteBy,
        actualRoute, 
    }
}