export default function Paragraph(props) {
    const content = props.children || props.content;

    return (
        <div className="pb-2">
            { props.title && <h4 className="pt-6 text-2xl">{props.title}</h4> }
            <p className="pt-1 text-lg font-thin leading-snug">
                { content }
            </p>
        </div>
    );
}