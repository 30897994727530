import { useSelector } from 'react-redux'
import axios from 'axios';

import Correction from './Correction'
import Answer from './Answer'

import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from "react-loader-spinner";

import { useState, useEffect } from 'react';
import useData from '../../hooks/useData';

export default function Question({ isExamMode, id, onResult, advancement }) {
    const [fetchData, questionDataLoading, questionData] = useData();
    const [questionResult, setQuestionResult] = useState(null);

    const showCorrection = questionResult!==null&&!isExamMode;

    const handleContinue = answer=>{
        let r=[]
        for(const [letter, isSelected] of Object.entries(answer)) {
            if(isSelected) r.push(letter)
        }
        let rE = r.sort().join('')
        setQuestionResult({
            correct: rE === questionData.reponse,
            answer: rE,
            correctness: questionData.reponse,
            question: id
        });
    }

    const nextQuestion = ()=>{
        onResult(questionResult)
    }

    useEffect(()=>{
        setQuestionResult(null)
        fetchData(id)
    }, [id])

    useEffect(()=>{
        if(questionResult!==null && isExamMode) {
            nextQuestion()
        }
    }, [questionResult]);

    return (
        <div className="lg:max-w-3xl">
            {questionDataLoading===true &&
                <Loader
                    type="Puff"
                    color="#00BFFF"
                    height={100}
                    width={100}
                    timeout={3000} //3 secs
                />
            }
            {questionDataLoading===false && <>
                    {questionResult===null && <Answer advancement={advancement} isExamMode={isExamMode} onContinue={handleContinue} data={questionData}/>}
                    {showCorrection && <Correction advancement={advancement} isExamMode={isExamMode} onContinue={nextQuestion} questionResult={questionResult} data={questionData}/>}
                </>
            }
        </div>
    );
}