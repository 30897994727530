import { useSelector } from 'react-redux'
import useAudio from '../../hooks/useAudio';

import React, { useEffect, useState } from 'react';
import AnswerCard from './Components/AnswerCard';
import ChronoBar from '../ChronoBar';
import Image from '../Image';

export default function Enonce(props) {
    const {enonce, id, proposition, reponse, sequence} = props.data

    const { etape, image, son, temps } = sequence[0];

    const enonceAudio = useAudio(id, son);

    const [answers, setAnswers] = useState({});

    const handleAnswer = answer=>{
        setAnswers({...answers, ...answer})
    }
    const handleContinue = ()=>{
        props.onContinue(answers)
    }

    useEffect(()=>{
        return function() {
            enonceAudio.audio.current.pause()
        }
    },[])

    

    const LETTERS = ['A','B','C','D'];
    const enonceBase = enonce[0];
    const enonceOptional = enonce.slice(1);

    return (
        <div className="bg-gray-700 overflow-hidden rounded-xl">
            <div className="relative w-full">
                <Image questionId={id} image={image[0].filename}/>
            </div>
            {enonceAudio.ended && <ChronoBar duration={temps} onEnd={handleContinue} /> }
            <div className="flex flex-col md:px-2">
                <div className="p-6 pb-0 enonce text-lg">
                    {enonceBase}
                </div>
                <div className="flex flex-col">
                    {enonceOptional.map((opt,i)=>{
                        const p = enonce[2]==='ou'?[i]:[2*i,2*i+1]
                        return (<div 
                                className="flex flex-col px-4"
                                key={i}
                            >
                            {(opt && opt!=='') && <div className="px-2 pt-4 text-lg">{opt}</div>}
                            <div className="flex flex-row flex-wrap">
                                { p.map((i,n)=>(proposition[i] && proposition[i]!=='') && <AnswerCard onChange={handleAnswer} text={proposition[i]} letter={LETTERS[i]} key={n} />) }
                            </div>
                        </div>);
                    })}
                </div>
                <div className="px-6 pb-6 flex flex-row flex-wrap justify-center md:justify-between continue">
                    <div className={`rounded-md py-2 mt-2 px-3 `}>
                        Question n°{props.advancement.actual} sur {props.advancement.total}
                    </div>
                    <div 
                        onClick={handleContinue}
                        className={`rounded-md py-2 mt-2 px-4 bg-green-400 hover:bg-green-500 cursor-pointer`}
                    >{props.isExamMode?'Question suivante':'Voir la correction'}</div>
                </div>
            </div>
        </div>
    );
}