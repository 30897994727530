import {useState, useEffect, useRef} from 'react'
import { useSelector } from 'react-redux'
import axios from 'axios'
// import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
// import Loader from "react-loader-spinner";

export default function useData() {
    const isLoading = useRef(null);
    const [dataFetched, setDataFetched] = useState(false);
    const sessionToken = useSelector(state => state.sessionToken.value)

    function handleData(data) {
        isLoading.current=false
        delete data.downloaded;
        setDataFetched({...data,});
    }

    function fetchData(questionId) {
        isLoading.current=true;
        axios({
            method: 'GET',
            url: `${process.env.REACT_APP_API_ENDPOINT}/api/question/${questionId}/data.json`,
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${sessionToken}`
            }
        }).then(r=>handleData(r.data))
    }

    return [fetchData, isLoading.current, dataFetched];
}