import React from 'react';
import { useLocation, Link } from 'react-router-dom';
import useRoutes from '../hooks/useRoutes';

export default function Header(props) {
    const location = useLocation();
    const {routes, getRouteBy} = useRoutes();
    let subRoutes = [];

    const addRoute = searchPath=>{
        subRoutes.push(getRouteBy('path',searchPath))
    } 


    if(location.pathname==='/') {
        addRoute('/')
    } else if(getRouteBy('path',location.pathname).name==='404') {
    } else {
        location.pathname.split('/').reduce((oldPath, segment)=>{
            if(segment==='') return oldPath;
            let newPath = `${oldPath}/${segment}`
            addRoute(newPath)
            return newPath
        }, '');
    }




    return (
        <header id="header">
            <nav className="flex flex-row flex-wrap py-8 md:py-12 px-8 md:px-28 text-xl md:text-2xl">
                <Link to="/"><span className="font-medium">{process.env.REACT_APP_NAME}</span></Link>
                {
                    subRoutes.map((route,i)=>(<React.Fragment key={i}>
                        <span className="font-thin px-2 text-gray-500">></span>
                        { i===subRoutes.length-1 ?
                            <span className="font-thin">{route.title}</span>
                            :
                            <Link to={route.path}><span className="font-medium">{route.title}</span></Link>
                        }
                    </React.Fragment>))
                }
            </nav>
        </header>
    )
}