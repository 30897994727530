import { useEffect, useState } from "react";

export default function Input(props) {
    const [value, setValue] = useState('');
    const [focused, setFocused] = useState(false);
    const [labelUp, setLabelUp] = useState(false);

    useEffect(function() {
        if(props.value && props.value!=='') {
            setValue(props.value)
            setLabelUp(true)
        }
    }, [])

    function handleFocus() {
        setFocused(true);
        setLabelUp(true);
    }

    function handleBlur() {
        setFocused(false);
        setLabelUp(value!=='');
    }
    function handleChange(event) {
        setValue(event.target.value);
        props.onChange({
            array: [props.name, event.target.value],
            toState: {
                [props.name]:event.target.value
            }
        });
    }

    return (
        <div className="flex flex-col">
            <div className={`flex flex-col w-auto bg-gray-600 rounded-lg text-lg mt-2 ${focused && "ring ring-gray-100"} ${props.error && 'border-2 border-red-400'}`}>
                {props.label && <label 
                    className={`duration-100 select-none absolute text-gray-400 mx-3 my-3 ${labelUp && "text-sm ml-3 mt-1"}`}
                    htmlFor={props.name}
                >
                    {props.label}
                </label>}
                
                <input 
                    onFocus={()=>handleFocus()}
                    onBlur ={()=>handleBlur()}
                    onChange={e=>handleChange(e)}
                    className="z-10 bg-transparent px-3 pt-5 pb-1 border-none outline-none"
                    autoComplete={props.name}
                    name={props.name}
                    type={props.type}
                    placeholder={props.placeholder}
                    value={value}
                />
            </div>
            {props.error && <span className="pt-1 pl-2 pb-3 text-red-400 text-sm font-semibold">
                {props.error}
                </span>}
        </div>
    );
}