import {useState, useEffect, useRef} from 'react'
import { useSelector } from 'react-redux'
import axios from 'axios'

export default function useAudio(questionId, file, type='mp3') {
    const audio = useRef(null);
    const [playing, setPlaying] = useState(null);
    const [ended, setEnded] = useState(false);
    const sessionToken = useSelector(state => state.sessionToken.value)

    const MIMES = {
        'aac': 'audio/aac',
        'mid': 'audio/midi',
        'midi': 'audio/midi',
        'mp3': 'audio/mpeg',
        'oga': 'audio/ogg',
        'opus': 'audio/opus',
        'wav': 'audio/x-wav',
        'weba': 'audio/webm',
        '3gp': 'audio/3gpp',
        '3g2': 'audio/3gpp3'
    }

    const pause = ()=>setPlaying(false);
    const play = ()=>setPlaying(true);

    function handleEnded() {
        console.log('audio ended');
        setPlaying(false);
        setEnded(true)
    }
    useEffect(()=>{
        if(playing===null) {
            axios({
                method: 'GET',
                url: `${process.env.REACT_APP_API_ENDPOINT}/api/question/${questionId}/sequence/${file}.${type}`,
                responseType: 'arraybuffer',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${sessionToken}`
                }
            }).then(r=>{
                audio.current = new Audio(URL.createObjectURL(
                    new Blob([r.data], {type: MIMES[type]})
                ));
                setPlaying(true)
            });
        } else if(playing) {
            audio.current.play()
            audio.current.addEventListener('ended', handleEnded);
        } else {
            audio.current.pause();
            audio.current.removeEventListener('ended', handleEnded);
        }

        return function() {
            // console.log('e');
        }
    }, [playing, audio.current])

    return {
        audio,
        pause,
        play,
        playing,
        setPlaying,
        ended
    }
}