import { createSlice } from '@reduxjs/toolkit'

export const serieOngoingSlice = createSlice({
    name: 'serieOngoing',
    initialState: {
        value: null,
    },
    reducers: {
        set: (state, action) => {
            state.value = action.payload
        },
    },
})

// Action creators are generated for each case reducer function
export const { set } = serieOngoingSlice.actions

export default serieOngoingSlice.reducer