import View from '../components/View';
import { Link } from 'react-router-dom';

export default function Error404(props) {


    return (
        <View>
            <div className="px-4 md:px-8 flex flex-col items-start">
                <span className="pt-20 text-6xl">Erreur 404</span>
                <span className="ml-1 text-3xl font-thin">Page introuvable</span>
                <Link className="mx-1 mt-10 rounded-md px-4 py-2 bg-gray-600 hover:bg-gray-700" to="/">Accueil</Link>
            </div>
        </View>
    );
}