import QuestionCard from './Components/QuestionCard';
import { useHistory } from 'react-router-dom';

import { serieOngoingReducer } from '../../app/store';
import { useState } from 'react';
import LittleCorrection from './Components/LittleCorrection';

export default function Results({ results }) {

    const [showCorrection, setShowCorrection] = useState({});

    const history = useHistory();

    const p = 100*results.score/results.nbQuestions

    const txResult = [
        [99, "C'est parfait !"],
        [95, "On frise l'excellence..."],
        [90, "Bravo, c'est super"],
        [87.5, "Tu as le code, continue comme ça"],
        [85, "Tu y es presque, continue!"],
        [70, "Les efforts payeront..."],
        [0, "Continue de t'entraîner, tu vas progresser !"],
    ]

    let t ='';

    for(let i=0; i<txResult.length;i++) {
        if(p>=txResult[i][0]) {
            t=txResult[i][1]
            break;
        }
    }

    const showQuestionCorrection = (n, question)=>{
        setShowCorrection({
            n,
            question
        });
    }

    const handleFinish = ()=>{
        history.push('/series');
    }

    return (
        <div className="lg:max-w-4xl">
            <div className="bg-gray-700 overflow-hidden rounded-xl flex flex-col justify-center items-center py-8 px-4 md:px-20 text-center">

                <div className="p-4">
                    <span 
                        className="font-bold text-5xl bg-clip-text text-transparent" 
                        style={{
                            backgroundImage:'linear-gradient(90deg, rgba(236,33,33,1) 0%, rgba(65,205,19,1) 100%)',
                            backgroundSize: '1100px 50px',
                            backgroundPositionX: `-${p*10}px`
                        }}
                    >
                        {results.score}
                    </span>
                    <span className="font-thin text-xl">
                    /{results.nbQuestions}
                    </span>
                </div>

                <div className="pb-6 pt-2">
                    Tu as {Math.round(10*p)/10}% de bonnes réponses (tu as le code à partir de 87.5%)
                </div>

                <div className="text-lg pb-6 pt-2">
                    {t}
                </div>

                { showCorrection.question &&
                    <div className="py-4 md:w-2/3">
                        <LittleCorrection questionToShow={showCorrection.question} advancement={{
                            actual: showCorrection.n,
                            total: results.nbQuestions
                        }} />
                    </div>
                }

                <div className="flex flex-row flex-wrap justify-start items-center">
                    {Object.values(results.details).map((question,index)=><QuestionCard key={index} n={index+1} selected={showCorrection.n===index+1} question={question} onClick={showQuestionCorrection}/>)}
                </div>

                <div className="pt-6">
                    <div className="px-4 py-2 bg-green-400 hover:bg-green-500 rounded-md cursor-pointer" onClick={handleFinish}>Retour à l'entraînement</div>
                </div>
            </div>
        </div>
    );
}