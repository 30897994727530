import { Redirect, useLocation } from "react-router-dom";
import useLoggedGuard from "./guards/useLoggedGuard";
import useSerieOngoingGuard from "./guards/useSerieOngoingGuard";

export default function useViewGuard() {
    const location = useLocation();

    const GUARDS = {
        LOGGED: useLoggedGuard(),
        SERIE_ONGOING: useSerieOngoingGuard()
    };

    const checkGuard = (routeGuards) => {
        if(!routeGuards || routeGuards.length===0) return {continue:true};

        let failed = [];

        let failTests = {};

        routeGuards.split(/\W+/gm).forEach(guardTest => {
            failTests[guardTest] = GUARDS[guardTest].check(failed);
        });


        return {
            continue: eval(routeGuards.replace(/([A-Z]|_)+/gm, "failTests.$&")), // stop on first fail
            failed
        };
    }


    const renderGuardedView = async route => {
        if(route.path!==location.pathname) return route.component;
        
        const check = checkGuard(route.guards);
        if(check.continue) {
            return route.component
        } else {
            let fails = [];
            for (let guard of check.failed) {
                const fail = await guard.fail(route)
                if(fail !== undefined) fails.push(fail)
            }

            return fails[fails.length-1]


            // return <Redirect to="/" />
        }
    }

    return [
        renderGuardedView
    ]
}