import { useSelector } from "react-redux"
import { Redirect } from 'react-router-dom'

export default function useSerieOngoingGuard() {
    const serieOngoing = useSelector(state=>state.serieOngoing.value);

    return {
        name: 'SERIE_ONGOING',
        condition: ()=>serieOngoing!==null,
        check: function(failedArray){
            if(this.condition()) return true;
            
            failedArray.push(this);
            return false;
        },
        // fail: async ()=><Redirect to="/series" />
        fail: async ()=>document.location.href="/series"
    }
}