import './App.css';
import { Route, Switch, withRouter } from 'react-router-dom';
import React, {useEffect, useState} from "react";
import { AnimatePresence } from "framer-motion";

import InitialTransition from '../components/InitialTransition';

import useRoutes from '../hooks/useRoutes'

import {Helmet} from "react-helmet";

import useViewGuard from '../hooks/useViewGuard';

    // carottebleue17
    // laitue_kaki_38

export default withRouter(function App(props) {
    const [renderGuardedView] = useViewGuard();

    const [views, setViews] = useState([]);
    const { location } = props

    const { routes } = useRoutes();

    useEffect(async ()=>{
        let tempViews=[];
        for (let route of routes) {
            const v = await renderGuardedView(route);
            tempViews.push([route.path,v])
        }
        setViews(tempViews)
    }, [])


    return (
        <>
            <InitialTransition/>
            <AnimatePresence exitBeforeEnter>
                <Switch location={location} key={location.pathname}>
                    {views.map(([path, view],i)=>(
                        <Route exact path={path} key={i}>
                            {view}
                        </Route>
                    ))}

                    {/* {routes.map((route,i)=>(
                        <Route exact path={route.path} key={i}>
                            { renderGuardedView(route) }
                        </Route>)
                    )} */}
                </Switch>
            </AnimatePresence>
        </>
    );

   
})