import randomOfArray from './randomOfArray'

function getRightColor(name, color) {
    return Array.isArray(color)?color[name[0]]:color;
}

export default function genUsername() {
    const colours = [
        'jaune',
        'rouge', 
        'pourpe',
        'orange',
        'ivoire',
        'beige',
        'rose',
        'kaki',
        'marron',
        'bordeaux',
        'saumon',
        ['brun','brune'],
        ['vert','verte'],
        ['violet', 'violette'],
        ['noir', 'noire'],
        ['blanc', 'blanche'],
        ['bleu', 'bleue']
    ];

    const names = [
        [1,'aubergine'],
        [1,'betterave'],
        [1,'carotte'],
        [0,'brocolis'],
        [0,'chou'],
        [0,'haricot'],
        [0,'radis'],
        [1,'laitue'],
        [1,'endive'],
        [1,'courge'],
        [0,'poireau'],
        [1,'salade'],
        [1,'tomate'],
        [0,'navet']
    ];

    let name = randomOfArray(names);
    let color = randomOfArray(colours);
    return `${name[1]}_${getRightColor(name, color)}_${parseInt(Math.random()*100)}`
}