import { configureStore } from '@reduxjs/toolkit'
import sessionToken, * as sessionTokenReducer from '../features/sessionToken/sessionTokenSlice'
import serieOngoing, * as serieOngoingReducer from '../features/serieOngoing/serieOngoingSlice'
import user, * as userReducer from '../features/user/userSlice'

export default configureStore({
    reducer: {
        serieOngoing,
        sessionToken,
        user,
    },
}, window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__())

export {
    serieOngoingReducer,
    sessionTokenReducer,
    userReducer
}