// import Emoji from 'a11y-react-emoji'

import View from '../components/View';
import Form from '../components/Form';
import FlexGrid from '../components/FlexGrid';
import SectionLink from '../components/SectionLink';
import Section from '../components/Section';
import { AnimatePresence } from "framer-motion";

import genUsername from '../services/genUsername';
import useAccount from '../hooks/useAccount';
import { useEffect } from 'react';
import Paragraph from '../components/Paragraph';

export default function Home(props) {
    const { user, isGuest, isLogged, login, logout, loginFromLocal} = useAccount();




    const loginBody = [
        ['input', {name:'username', label:'Pseudo'}],
        ['submit', {title:"Continuer l'entraînement"}]
    ];

    const registerBody = [
        ['input', {name:'username', label:'Pseudo (à retenir)'}],
        ['input', {name:'lastname', label:'Nom'}],
        ['input', {name:'firstname', label:'Prénom'}],
        ['submit', {title:'Commencer'}]
    ];

    const logoutBody = [
        ['submit', {title:'Se déconnecter'}]
    ];

    useEffect(function() {
        loginFromLocal()
        console.log('username generation');
        registerBody[0][1].value = genUsername();
    }, [])


    

    return (
        <View>
            <div className="px-4 md:px-8">
                <FlexGrid row>
                    <AnimatePresence exitBeforeEnter>
                    {isGuest && 
                    <Section key="register" title="Créer un compte" subtitle="Pour accéder au site" collapse>
                        <Form method="POST" url={`${process.env.REACT_APP_API_ENDPOINT}/auth/register`} onSuccess={r=>login(r.data.response.sessionToken)} body={registerBody} />
                    </Section>
                    }
                    {isGuest && 
                    <Section key="login" title="Se connecter" subtitle="Pour continuer de s'entraîner" collapse>
                        <Form method="POST" url={`${process.env.REACT_APP_API_ENDPOINT}/auth/login`} onSuccess={r=>login(r.data.response.sessionToken)} body={loginBody} />
                    </Section>
                    }
                    {isLogged && 
                        <Section key="user" title={`${user.name.first} ${user.name.last}`} subtitle="Où en étions-nous?">
                            <Form method="POST" url={`${process.env.REACT_APP_API_ENDPOINT}/auth/logout`} onSuccess={r=>logout(r)} body={logoutBody} />
                        </Section>
                    }
                    <Section key="about" title={`À propos de Yota`} subtitle="C'est quoi? Pourquoi?" collapse>
                        <Paragraph title="C'est quoi?">
                            <b className="font-semibold">Yota</b> est un projet personnel réalisé dans le cadre d'un apprentissage du framework ReactJs.
                        </Paragraph>
                        <Paragraph>
                            Il est plus intéressant de travailler et d'apprendre lorsqu'il y a un contexte. Ce site est donc un prototype factice de site pour apprendre le code de la route.
                        </Paragraph>
                        <Paragraph>
                                Avec ce projet j'ai appris à gérer les routes, l'architecture d'un site en React, j'ai découvert les Hooks, Redux, Framer Motion, etc.
                        </Paragraph>
                        <Paragraph title="Quelle stack derrière ce site?">
                            ReactJs en front, et NodeJs avec Express pour l'api. Au niveau de la base de données c'est vers FireBase de Google que je me suis tourné.
                        </Paragraph>
                    </Section>
                    </AnimatePresence>
                </FlexGrid>
                
                <FlexGrid row>
                    
                    <SectionLink 
                        disabled={isGuest}
                        to="series" 
                        img="/assets/img/train-1x.jpg" 
                        title="S'entraîner" 
                        subtitle="En répondant à des questions"
                    />
                    <SectionLink 
                        disabled={isGuest}
                        to="stats" 
                        img="/assets/img/stats-1x.jpg" 
                        title="Statistiques"
                        subtitle="Voir sa progression"
                    />
                    <SectionLink 
                        disabled={true}
                        to="series" 
                        img="/assets/img/test-1x.jpg" 
                        title="S'évaluer (bientôt)" 
                        subtitle="Avec des examens blanc"
                    />
                </FlexGrid>
            </div>
        </View>
    );
}