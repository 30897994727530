import View from '../components/View';

import SerieQuizz from '../components/SerieQuizz';
import axios from 'axios';
import { AnimatePresence } from "framer-motion";

import { useSelector } from 'react-redux'

export default function Train(props) {
    const isExamMode = props.examMode;
    const serieOngoing = useSelector(state => state.serieOngoing.value)
    const sessionToken = useSelector(state => state.sessionToken.value)

    console.log(serieOngoing);

    function endSerie(results) {
        axios({
            method: 'POST',
            url: `${process.env.REACT_APP_API_ENDPOINT}/api/serie/end`,
            data: {
                serie: serieOngoing,
                results
            },
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${sessionToken}`
            }
        })
        .then(r=>{
            console.log(r.data);
        })
    }

    return (
        <View>
            <div className="px-4 md:px-8 flex flex-row items-center justify-center">
                <AnimatePresence exitBeforeEnter>
                    <SerieQuizz serie={serieOngoing} isExamMode={isExamMode} onResults={results=>endSerie(results)} />
                </AnimatePresence>
            </div>
        </View>
    );
}