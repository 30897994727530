import { createSlice } from '@reduxjs/toolkit'
import serviceName from '../../services/serviceName';

export const sessionTokenSlice = createSlice({
    name: 'sessionToken',
    initialState: {
        value: null,
    },
    reducers: {
        set: (state, action) => {
            state.value = action.payload
            localStorage.setItem(serviceName('sessionToken'), JSON.stringify(action.payload));
        },

        load: (state) => {
            let st = JSON.parse(localStorage.getItem(serviceName('sessionToken')));
            if(st) {
                state.value = st;
            }
        },

        invalid: (state) => {
            state.value = null
            localStorage.removeItem(serviceName('sessionToken'));
        }
    },
})

// Action creators are generated for each case reducer function
export const { set, load, invalid } = sessionTokenSlice.actions

export default sessionTokenSlice.reducer