import { useEffect, useState, useRef } from "react";

export default function AnswerCard(props) {
    const [isSelected, setIsSelected] = useState(false);
    const firstUpdate = useRef(true);

    useEffect(()=>{
        if (firstUpdate.current) {
            firstUpdate.current = false;
            return;
        } else {
            props.onChange({
                [ props.letter ]: isSelected
            })
        }
        
    }, [isSelected]);

    return (
        <div 
            className={`flex flex-row justify-center items-center m-1 p-1 pr-2 rounded-md border-2 border-transparent hover:border-gray-400 cursor-pointer`}
            onClick={()=>setIsSelected(!isSelected)}
        >
            <div className={`px-3 py-1 mr-2 rounded-md bg-${isSelected?'green':'gray'}-400`}>{props.letter}</div>
            <span className={`text-${isSelected?'green-400':'white'}`}>{props.text}</span>
        </div>
    );
}