import Header from './Header';
import { motion } from "framer-motion"
import {Helmet} from "react-helmet";
import useRoutes from '../hooks/useRoutes';

export default function View(props) {
    const { routes, actualRoute } = useRoutes();
    return (
        <div className="min-h-screen bg-gray-800 text-white">
            <Helmet>
                <title>{process.env.REACT_APP_NAME} - {actualRoute().title}</title>
            </Helmet>

            <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.5 }}
            >
                <Header />
                <main className="pb-20 md:px-16 main-route-place">
                    {props.children}
                </main>
            </motion.div>
        </div>
    )
}